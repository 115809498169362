var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-12"},[(_vm.renderState)?_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":""}})],1):_c('article',[_c('v-breadcrumbs',{staticClass:"px-0 my-2",attrs:{"items":_vm.breadItems}}),_c('div',[_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('div',{staticClass:"mb-2"},[_vm._v("トレード結果の書き方については、"),_c('a',{attrs:{"href":"#"}},[_vm._v("こちら")]),_vm._v("を参考にしてください。")]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"name":"通貨ペア","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","outlined":"","item-text":"name","item-value":"id","label":"通貨ペア","items":_vm.initData.thread.currency_pair,"error-messages":errors,"hide-details":"auto"},model:{value:(_vm.inputs.currency_pair_id),callback:function ($$v) {_vm.$set(_vm.inputs, "currency_pair_id", $$v)},expression:"inputs.currency_pair_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"name":"エントリー方向","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","outlined":"","item-text":"name","item-value":"id","label":"エントリー方向","items":_vm.initData.thread.entry_direction,"error-messages":errors,"hide-details":"auto"},model:{value:(_vm.inputs.entry_direction_id),callback:function ($$v) {_vm.$set(_vm.inputs, "entry_direction_id", $$v)},expression:"inputs.entry_direction_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"name":"エントリー","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"エントリー","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.inputs.entry),callback:function ($$v) {_vm.$set(_vm.inputs, "entry", $$v)},expression:"inputs.entry"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"name":"エグジット","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"エグジット","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.inputs.exit),callback:function ($$v) {_vm.$set(_vm.inputs, "exit", $$v)},expression:"inputs.exit"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"name":"予定T/P","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"予定T/P","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.inputs.schedule_tp),callback:function ($$v) {_vm.$set(_vm.inputs, "schedule_tp", $$v)},expression:"inputs.schedule_tp"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"name":"予定S/L","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"予定S/L","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.inputs.schedule_sl),callback:function ($$v) {_vm.$set(_vm.inputs, "schedule_sl", $$v)},expression:"inputs.schedule_sl"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('validation-provider',{attrs:{"name":"エントリー日時","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"エントリー日時","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.inputs.entry_date),callback:function ($$v) {_vm.$set(_vm.inputs, "entry_date", $$v)},expression:"inputs.entry_date"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"コメント","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","label":"コメント","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.inputs.body),callback:function ($$v) {_vm.$set(_vm.inputs, "body", $$v)},expression:"inputs.body"}})]}}],null,true)})],1)],1),_c('v-alert',{staticClass:"my-2 mb-5",attrs:{"dense":"","text":"","type":"info"}},[_vm._v(" チャート画像添付(ファイル形式は、png, jpg, gifのいずれか) ")]),_c('file-upload',{attrs:{"files":_vm.inputs.files.length},on:{"success":_vm.uploadSuccess}},[(_vm.inputs.files.length > 0)?_c('template',{slot:"preview"},_vm._l((_vm.inputs.files),function(file){return _c('div',{key:file.id,staticClass:"preview-item mr-2 mb-2"},[_c('v-btn',{attrs:{"icon":"","x-small":"","absolute":"","top":"","right":"","color":"error"},on:{"click":function($event){return _vm.removeFile(file)}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-img',{staticClass:"thumbnail",attrs:{"src":file.url,"contain":"","width":"80vw"},on:{"click":function($event){return _vm.overlayPreview(file.url)}}})],1)}),0):_vm._e()],2),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"primary",attrs:{"large":"","loading":_vm.loading,"disabled":invalid},on:{"click":_vm.submit}},[_vm._v("更新")])],1)],1)]}}])})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.overlayState.state,"opacity":"0.6","z-index":"9000"}},[_c('div',[_c('v-img',{attrs:{"src":_vm.overlayState.src,"max-width":"90vw","height":"auto"},on:{"click":function($event){_vm.overlayState.state = false}}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }