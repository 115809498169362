



























































































































































import { defineComponent, onMounted, reactive, ref, inject, SetupContext } from '@vue/composition-api'
import FileUpload from '@/components/FileUpload.vue'
import { useInit } from '@/hooks/useInit'
import { NotificationInjectKey } from '@/hooks/useNotification'
import { FileUploadResponseType, InvestmentItemType, AttachmentFileType } from '@/types'
import { headerWithAccessToken, refreshAccessToken } from '@/libs'
import axios from 'axios'
const client = axios.create({
  withCredentials: true,
})
export default defineComponent({
  components: { FileUpload },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const user = vm.$auth.user()
      const capabilitie_investment_id = user?.capabilitie_investment_id
      if (capabilitie_investment_id === 1) {
        vm.$router.push('/investment')
      }
    })
  },
  setup(_, context: SetupContext) {
    const renderState = ref(true)
    const notificationService = inject(NotificationInjectKey)
    const loading = ref(false)
    const { data: initData, fetch } = useInit()
    const inputs = reactive<InvestmentItemType>({
      id: 1,
      currency_pair_id: null,
      entry_direction_id: null,
      entry: null,
      exit: null,
      schedule_tp: null,
      schedule_sl: null,
      entry_date: null,
      body: null,
      files: [],
      file_ids: [],
    })

    const breadItems = [
      {
        text: '投資勉強会',
        disabled: false,
        href: '/investment',
      },
      {
        text: 'みんなのトレード報告',
        disabled: false,
        href: '/investment/threads',
      },
      {
        text: 'トレード結果編集',
        disabled: true,
      },
    ]

    const overlayState = reactive({
      state: false,
      src: '',
    })

    const overlayPreview = (img: string) => {
      overlayState.src = img
      overlayState.state = true
    }

    const submit = async () => {
      loading.value = true
      const { uri } = context.root.$appConfig.endpoints.threadUpdate
      const { id } = inputs
      const endpoint = uri.replace(':id', (id as number).toString())
      await client
        .put(endpoint, inputs, {
          headers: headerWithAccessToken(uri),
        })
        .then((res) => {
          refreshAccessToken(res.headers)
          notificationService?.show('トレード結果を更新しました', 'success')
        })
        .catch((err) => {
          refreshAccessToken(err.response.headers)
          notificationService?.show('トレード結果の更新に失敗しました', 'error')
        })
        .finally(() => {
          loading.value = false
        })
    }

    const removeFile = (item: AttachmentFileType | FileUploadResponseType) => {
      inputs.files = inputs.files.filter((file) => file.id !== item.id)
      inputs.file_ids = inputs.file_ids.filter((id) => id !== item.id)
    }

    const replaceS3path = (url: string) => {
      const domain = context.root.$appConfig.s3
      return `${domain}${url}`
    }

    const uploadSuccess = (item: FileUploadResponseType) => {
      const domain = context.root.$appConfig.s3
      item.url = `${domain}${item.url}`
      inputs.files.push(item)
      inputs.file_ids.push(item.id)
    }

    const arrangePostsFiles = (inputs: InvestmentItemType) => {
      inputs.files.forEach((file) => {
        file.url = replaceS3path(file.url)
        const id = file.id as number
        inputs.file_ids.push(id)
      })
    }

    const fetchData = async (id: string): Promise<void> => {
      const user = context.root.$auth.user()
      const { uri } = context.root.$appConfig.endpoints.threadGet
      const endpoint = uri.replace(':id', id)
      const response = await client
        .get(endpoint, {
          headers: headerWithAccessToken(endpoint),
        })
        .then((res) => {
          refreshAccessToken(res.headers)
          return res.data
        })
        .catch((err) => {
          refreshAccessToken(err.response.headers)
          return false
        })
      if (!response || user?.id !== response.created_user_id) {
        notificationService?.show('サーバーとの通信に失敗しました', 'error')
        return
      }
      Object.keys(inputs).forEach((key) => {
        inputs[key] = response[key]
      })
      inputs.file_ids = []
      arrangePostsFiles(inputs)
      renderState.value = false
    }
    onMounted(async () => {
      const { params } = context.root.$route
      const id = params.id
      if (typeof parseInt(id) !== 'number') {
        context.root.$router.push({
          name: 'InvestmentThread',
        })
      }
      await fetch()
      await fetchData(id)
      renderState
    })

    return {
      replaceS3path,
      breadItems,
      inputs,
      submit,
      uploadSuccess,
      initData,
      removeFile,
      overlayState,
      overlayPreview,
      loading,
      renderState,
    }
  },
})
